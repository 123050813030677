<template>
	<div id="InviteAccountant">
		<!-- back to team members  -->
		<div class="sm:flex items-center m-auto text-center sm:text-left pt-6">
			<div class="w-full sm:w-1/2 flex mt-auto">
				<vs-button
					color="#2c2c2c"
					type="flat"
					icon-pack="feather"
					icon="icon-arrow-left"
					class="inline"
					@click="$router.push({ path: '/accountants' })"
				/>

				<h2 class="inline ml-2" style="line-height: 35px">{{ $t("Mes collaborateurs") }}</h2>
			</div>
		</div>

		<!-- Card principale avec le formulaire -->
		<vs-card class="shadow-none border mt-4 p-6">
			<div class="pb-8 text-center">
				<h4>{{ $t("Invitez un collaborateur à vous rejoindre") }}</h4>
			</div>

			<!-- cas erreur 401, on indique qu'un utilisateur possèdant l'email indiqué existe déjà dans l'App -->
			<vs-alert :active="msgErr === 401" type="warning" class="my-5">
				{{ $t("L'utilisateur que vous essayez d'inviter a déjà accès à l'application.") }}
			</vs-alert>

			<!-- cas erreur 403, on indique que l'utilisateur existe deja, on ne peut donc pas lui affecter de mot de passe -->
			<vs-alert
				:active="msgErr === 403"
				type="warning"
				class="my-5"
				:title="$t('L utilisateur que vous essayez de créer existe déjà')"
			>
				{{ $t("L'utilisateur que vous essayez de créer existe déjà ...") }}
			</vs-alert>

			<!-- cas erreur 422, on indique qu'une erreur Unprocessable entity est survenue, cela peut etre du a un probleme dans le code de l'interface ou de l'api -->
			<vs-alert :active="msgErr === 422" type="warning" class="my-5">
				{{ $t("Une erreur est survenue") }}
			</vs-alert>

			<!-- nom et prénom -->
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model.trim="firstname"
						v-validate="'required'"
						name="firstname"
						color="var(--black)"
						class="w-full"
						:label-placeholder="$t('Prénom')"
						autocomplete="off"
					/>
					<span v-show="errors.has('firstname')" class="text-danger text-sm">
						{{ errors.first("firstname") }}
					</span>
				</div>
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model.trim="lastname"
						v-validate="'required'"
						color="var(--black)"
						class="w-full mb-3"
						:label-placeholder="$t('Nom')"
						name="lastname"
						autocomplete="off"
					/>
					<span v-show="errors.has('lastname')" class="text-danger text-sm">
						{{ errors.first("lastname") }}
					</span>
				</div>
			</div>

			<!-- mail -->
			<div class="vx-col w-full py-1">
				<vs-input
					v-model.trim="email"
					v-validate="{ required: true, email: true, regex: new RegExp(`${accounting_domain}$`) }"
					name="email"
					color="var(--black)"
					class="w-full"
					:label-placeholder="$t('Email')"
					autocomplete="off"
				/>
				<span v-show="errors.has('email')" class="text-danger text-sm">{{ errors.first("email") }}</span>
			</div>

			<!-- apps -->
			<div class="vx-col w-full mb-3">
				<div class="vs-component vs-con-input-label vs-input w-full vs-input-var(--black) is-label-placeholder">
					{{ $t("Gère les applications") }}
				</div>
				<div class="border-rounded mt-1">
					<!-- select all app -->
					<div class="flex py-1 mb-3 rounded-lg" style="background-color: #eee">
						<vs-checkbox v-model="select_all" @change="toggleAllSelectedApps"></vs-checkbox>
						{{ $t("tout cocher decocher") }}
					</div>
					<!-- apps checkboxes -->
					<div v-for="(obj, index) in selected_apps" :key="'_app_' + obj.id + '_' + index" class="flex my-2">
						<vs-checkbox v-model="obj.right"></vs-checkbox>
						{{ obj.name }}
					</div>
				</div>
			</div>

			<div class="vx-col w-full py-1">
				<!-- mot de passe -->
				<div class="vx-col w-full py-3">
					<vs-checkbox v-model="sendEmail">
						{{ $t("Envoyer un mail d'invitation automatiquement") }}
					</vs-checkbox>
				</div>
			</div>
			<transition name="mdp-appear">
				<div v-if="!sendEmail" class="vx-row mdp">
					<div class="vx-col w-full sm:w-1/2">
						<vs-input
							ref="password"
							v-model="password"
							v-validate="validatePassword"
							class="w-full"
							name="password"
							color="var(--black)"
							type="password"
							:label-placeholder="$t('Mot de passe')"
						/>
						<span v-show="errors.has('password')" class="text-danger text-sm">
							{{ errors.first("password") }}
						</span>
					</div>
					<div class="vx-col w-full sm:w-1/2">
						<vs-input
							ref="password2"
							v-model="password2"
							v-validate="validatePassword2"
							class="w-full"
							name="password2"
							color="var(--black)"
							type="password"
							:label-placeholder="$t('Confirmer le mot de passe')"
						/>
						<span v-show="errors.has('password2')" class="text-danger text-sm">
							{{ errors.first("password2") }}
						</span>
					</div>
				</div>
			</transition>

			<!-- les boutons valider et annuler -->
			<div class="vx-row pt-8">
				<div class="vx-col w-full sm:w-1/2">
					<s-button type="border" class="w-full" @click="goBack"> {{ $t("Annuler") }} </s-button>
				</div>
				<div class="vx-col w-full sm:w-1/2">
					<s-button
						ref="submit"
						type="success"
						class="w-full vs-con-loading__container"
						:disabled="isComplete === false || disabled"
						@click="addAccountant"
					>
						{{ $t("Valider") }}
					</s-button>
				</div>
			</div>
		</vs-card>
	</div>
</template>
<script>
	import { Validator } from "vee-validate";
	import { mapGetters, mapState } from "vuex";
	import i18n from "../i18n";
	import store from "@/store/store";

	// dictionnaire des messages d'erreur inputs formulaire
	const dict = {
		custom: {
			firstname: {
				required: () => i18n.t("Veuillez entrer un prénom")
			},
			lastname: {
				required: () => i18n.t("Veuillez entrer un nom de famille")
			},
			email: {
				required: i18n.t("Veuillez entrer l'email"),
				email: i18n.t("L'email n'est pas valide"),
				regex: i18n.t("email domaine")
			},
			selectedPolicy: {
				required: i18n.t("Veuillez choisir les droits")
			},
			password: {
				required: i18n.t("Veuillez entrer un mot de passe"),
				min: i18n.t("Le mot de passe doit faire plus de 8 caractères")
			},
			password2: {
				required: i18n.t("Veuillez entrer un mot de passe"),
				min: i18n.t("Le mot de passe doit faire plus de 8 caractères"),
				confirmed: i18n.t("Les mots de passe ne correspondent pas")
			}
		}
	};

	// appel du dictionnaire pour la validation formulaire
	Validator.localize("fr", dict);

	export default {
		name: "InviteAccountant",
		data() {
			return {
				appId: this.$route.params.appId,
				app: null,
				organization: null,
				// form
				lastname: null,
				firstname: null,
				email: null,
				selected_apps: [],
				password: null,
				password2: null,
				sendEmail: true,
				// gestion erreurs
				msgErr: 0,
				complete: false,
				select_all: false,
				has_selected_app: false,
				disabled: false
			};
		},
		computed: {
			...mapGetters(["accounting_firm_apps", "accountant_policy_id", "accounting_domain"]),
			...mapState(["user"]),
			isComplete() {
				return !!(
					this.lastname?.length &&
					this.firstname?.length &&
					this.email?.length &&
					this.has_selected_app &&
					(this.sendEmail ||
						(!this.sendEmail &&
							this.password?.length &&
							this.password2?.length &&
							this.password === this.password2))
				);
			}
		},
		watch: {
			selected_apps: {
				handler(newValue) {
					this.has_selected_app = newValue.filter(obj => obj.right === true).length > 0;
				},
				deep: true,
				immediate: true
			}
		},
		async mounted() {
			if (!this.accountant_policy_id) {
				// impossible to create a user : notify and go back home
				this.$vs.notify({
					title: "Inscription réussie!",
					text: i18n.t("Policy id comptable absent."),
					color: "danger",
					iconPack: "feather",
					icon: "icon-alert-triangle",
					time: 5000
				});
				this.$router.push({ name: "Apps" });
			}
			this.initSelectedApps();
		},
		methods: {
			updateSelectedApps: function (appId) {
				if (this.accountant_selected_apps?.[appId] !== undefined) {
					delete this.accountant_selected_apps[appId];
				} else {
					this.accountant_selected_apps[appId] = true;
				}
			},
			toggleAllSelectedApps: function () {
				this.selected_apps.forEach(app => (app.right = this.select_all));
			},
			initSelectedApps: function () {
				this.accounting_firm_apps.forEach(app =>
					this.selected_apps.push({ id: app.id, right: false, name: app.organization.name })
				);
			},
			goBack() {
				this.$router.push({ name: "Accountants" });
			},
			async addAccountant() {
				const isFormValid = await this.$validator.validateAll();
				const _selected_apps = this.selected_apps.filter(app => app.right === true);
				if (isFormValid) {
					const payload = {
						appId: this.user.accounting_firm?.appId,
						email: encodeURIComponent(this.email),
						policyProfileId: this.accountant_policy_id,
						firstname: encodeURIComponent(this.firstname),
						lastname: encodeURIComponent(this.lastname)
					};
					if (_selected_apps.length > 1) {
						payload.additionalsAppId = _selected_apps.slice(1).map(app => app.id);
					}

					if (!this.sendEmail) {
						payload.password = this.password;
					}

					console.log("payload", payload);

					this.disabled = true;

					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--success)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					this.$store
						.dispatch("sendInviteMail", payload)
						.then(async () => {
							let title = null;
							let text = null;

							if (this.sendEmail) {
								title = i18n.t("Invitation envoyée");
								text = i18n.t(
									"Un nouvel utilisateur a été créé, un email d'invitation vient d'être envoyé."
								);
							} else {
								title = i18n.t("Utilisateur créé");
								text =
									"Un nouvel utilisateur a été créé, vous pouvez maintenant lui communiquer ses informations de connexion.";
							}

							this.$vs.notify({
								title,
								text,
								color: "success",
								iconPack: "feather",
								icon: "icon-check",
								time: 5000
							});

							// force resfresh /me
							await this.$store.dispatch("getUser");
							// on retourne à la page précédente
							this.goBack();

							this.msgErr = 200;
							this.$vs.loading.close(this.$refs.submit.$el);
						})
						.catch(err => {
							this.msgErr = err?.response?.status;
							this.$vs.loading.close(this.$refs.submit.$el);
						});
				}
			}
		}
	};
</script>

<style scoped>
	.border-rounded {
		padding: 0.5rem 0.75rem !important;
		border: 1px solid var(--border);
		border-radius: 6px !important;
		color: var(--text) !important;
	}
</style>
